













import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ListView from '../AList/ListView.vue';
import CockpitKeyfigures from '../sections/CockpitKeyfigures.vue';
import CockpitIdentification from '../sections/CockpitIdentification.vue';
import KeyfigureData from '@/components/Cockpit/models/KeyfigureData';
import CockpitSkeleton from '@/components/Cockpit/Controls/CockpitSkeleton.vue';
import InvestMenu from '@/components/Cockpit/InvestList/InvestMenu.vue';
import TableData from '../models/TableData';
import Events from '@/events';
import UserData from '../models/UserData';
import DateFormatHelper from '../../Helper/DateFormatHelper';
import RestApi from '../../Rest/RestApi';

import PageFinancingLanding from '@/components/Cockpit/pages/PageFinancingLanding.vue';
import TableDataService from '@/components/Cockpit/services/TableDataService';
import CockpitTabBar from '@/components/Cockpit/Controls/CockpitTabBar.vue';
import OptionEntry from '@/components/Cockpit/models/OptionEntry';
import ViewPager from '@/components/ViewPager/ViewPager.vue';
import PageView from '@/components/ViewPager/PageView.vue';
import StringHelper from '@/components/Helper/StringHelper';
import Payment from '@/components/Cockpit/interfaces/Payment';
import ListFinancingPayments from '@/components/Cockpit/Lists/ListFinancingPayments.vue';
import ListFinancingInvestment from '@/components/Cockpit/Lists/ListFinancingInvestment.vue';
import ExporoVue from '@/components/ExporoVue';
import PayoutService from '@/components/Cockpit/services/PayoutService';
import FinancingService from '@/components/Cockpit/services/FinancingService';
import ListFinancing from '@/components/Cockpit/Lists/ListFinancing.vue';
import InvestmentFactory from '@/components/Cockpit/Financing/InvestmentFactory';
import Investment, {InvestmentType} from '@/components/Cockpit/Financing/interfaces/investment';
import WalletApiService from '@/components/Wallet/services/WalletApiService';
import MezzBondInvestment from '@/components/Cockpit/Financing/MezzBondInvestment';
import LocalStorage from '@/components/LocalStorage/LocalStorage';
import TanganyApiService from '@/components/Wallet/services/TanganyApiService';

interface VaultData {
  asset: {
    deleted: boolean;
    name: string;
    token: string;
  };
  balance: {
    balance: number;
    currency: string;
  };
}

@Component({
    components: {
        ListView,
        InvestMenu,
        CockpitKeyfigures,
        CockpitIdentification,
        CockpitTabBar,
        CockpitSkeleton,
        PageFinancingLanding,
        ViewPager,
        PageView,
        ListFinancingPayments,
        ListFinancingInvestment,
        ListFinancing,
    },
})
class PageFinancing extends ExporoVue {

    private keyfigures: KeyfigureData[] = [];
    private investmentTableData: Investment[];
    private paymentTableData: TableData;
    private financingService: FinancingService;
    private walletApiService: WalletApiService;

    @Prop() private userdata!: UserData;
    @Prop() private userRestApi!: RestApi;

    private pageOptions: any[][];
    private loaded: boolean = false;
    private payments: Payment[] = [];

    private onChangeTabCallback: any = null;
    private dateFormat = ((x) => DateFormatHelper.getFormattedDateFromString(x, true));

    constructor() {
        super();
        this.investmentTableData = [];
        this.paymentTableData = new TableData([]);
        this.financingService = new FinancingService();
        this.walletApiService = new WalletApiService();

        this.pageOptions = [
            [
                new OptionEntry(
                    this.trans.get('cockpit.pages.financing.tabbar.investments'),
                    'investments',
                    () => {
                    },
                ),
                new OptionEntry(
                    this.trans.get('cockpit.pages.financing.tabbar.payments'),
                    'payments',
                    () => {
                    },
                ),
            ],
        ];
    }



    mounted() {
        const viewPager: ViewPager = this.$refs.viewPager as ViewPager;

        this.onChangeTabCallback = (tab: any) => {
            viewPager.setCurrentItem(tab);
        };

        if (this.userdata) {
          const userid = LocalStorage.get(ExporoVue.USER_ID_KEY, '');
          const tanganyService = new TanganyApiService();
          tanganyService.getWallet(userid).then((result: any) => {
            const vaultData: VaultData = result?.data;
            this.createInvestments(vaultData);
            this.getUniquePayments();
            this.createInvestmentTableData();
            this.createPaymentTableData();

            this.loaded = true;
          });


        }

    }

    private createInvestments(vaultData: VaultData) {
        const investmentFactory = new InvestmentFactory();
        const contracts = this.userdata.contracts.bond.concat(this.userdata.contracts.equity);
        const investments = this.userdata.rawData.investmentData.finanzierung.investments;
        const pushedInvestmentIsins: string[] = [];

      // walletBalances?.forEach((walletEntry) => {
      //   if (walletEntry.amount === '0') return;
      //
      //   contracts.forEach((contract) => {
      //     if ((contract.product_type === 'finanzierung' || contract.type === 'product.mezzbond')
      //         && contract.blockchain_asset_id === walletEntry.assetId) {
      //       contract.invest_sum = walletEntry.amount;
      //       pushedInvestmentIsins.push(contract.isin);
      //       this.investmentTableData.push(investmentFactory.createInvestment(contract, this.userdata));
      //     }
      //   });
      // });

        if (vaultData && vaultData.balance && vaultData.balance.balance && vaultData.balance.balance > 0) {
          contracts.forEach((contract) => {
            if ((contract.product_type === 'finanzierung' || contract.type === 'product.mezzbond')
                && contract.blockchain_asset_id === vaultData.asset.token) {
              contract.invest_sum = vaultData.balance.balance;
              pushedInvestmentIsins.push(contract.isin);
              this.investmentTableData.push(investmentFactory.createInvestment(contract, this.userdata));
            }
          });
        }

        investments?.forEach((investment) => {
            if (!pushedInvestmentIsins.find((isin) => (isin === investment.isin || isin === investment.contract?.isin))) {
                this.investmentTableData.push(investmentFactory.createInvestment(investment, this.userdata));
            }
        });
    }

    getPaymentsV2() {
        let payments: Payment[] = [];
        const paymentsObject = this.userdata.rawData.investmentData.finanzierung.payments;

        if (paymentsObject.hasOwnProperty('loan')) {
            Object.keys(paymentsObject.loan).forEach((id) => {
                if (paymentsObject.loan[id].payments) {
                    payments = payments.concat(paymentsObject.loan[id].payments);
                }
            });
        }

        if (paymentsObject.hasOwnProperty('equity')) {
            Object.keys(paymentsObject.equity).forEach((id) => {
                if (paymentsObject.equity[id].payments) {
                    payments = payments.concat(paymentsObject.equity[id].payments);
                }
            });
        }

        if (paymentsObject.hasOwnProperty('bond')) {
            Object.keys(paymentsObject.bond).forEach((id) => {
                if (paymentsObject.bond[id].payments) {
                    payments = payments.concat(paymentsObject.bond[id].payments);
                }
            });
        }

        return payments;
    }

    getPaymentsV1() {
        const paymentsObject = this.userdata.rawData.investmentData.finanzierung.payments;
        const investmentObject = this.userdata.financingInvestments;
        let payments: Payment[] = [];


        Object.keys(paymentsObject).forEach((id) => {
            if (paymentsObject[id].payments) {
                payments = payments.concat(paymentsObject[id].payments);
            }
        });

        investmentObject.forEach((investment) => {
            if (investment.contract) {
                payments = payments.concat(investment);
            }
        });

        return payments;
    }

    getUniquePayments() {
        const paymentsObject = this.userdata.rawData.investmentData.finanzierung.payments;

        if (paymentsObject.hasOwnProperty('loan')) {
            this.payments = this.getPaymentsV2();
        } else {
            this.payments = this.getPaymentsV1();
        }
    }

    getInvestmentByPaymentId(id: any) {
        return this.userdata.financingInvestments.find((investment) => {
            return investment.signing_id === id;
        });
    }

    getPaymentByInvestmentId(payments, investmentId) {
        return payments.find((payment) => {
            return payment.investment_id === investmentId;
        });
    }

    createInvestmentTableData() {
        const sumFundedProjects: number = this.financingService.calcSumOfFundedProjects(this.investmentTableData);
        const averageInvestment: number = this.financingService.calcAverageInvestment(this.investmentTableData);

        TableDataService.add('financing', this.investmentTableData);

        this.keyfigures.push(new KeyfigureData(
            this.trans.get('cockpit.pages.financing.keyfigures.count_running_investments'),
            this.investmentTableData.filter((investment: Investment) => investment.isRunning()).length.toString(),
        ));

        this.keyfigures.push(new KeyfigureData(
            this.trans.get('cockpit.pages.financing.keyfigures.sum_running_investments'),
            Number(Math.round(sumFundedProjects)).toLocaleString('de-DE') + '€',
        ));

        this.keyfigures.push(new KeyfigureData(
            this.trans.get('cockpit.pages.financing.keyfigures.return_running_investments'),
            this.financingService.formatNumber(isNaN(averageInvestment) ? 0 : averageInvestment, 2) + '%',
        ));

        Events.$emit('filter', {type: 'state', selection: 'ongoingInvestments', subMenu: 'investments'});
    }

    createPaymentTableData() {
        const paymentTableData = this.getPaymentEntries();
        this.paymentTableData = new TableData(paymentTableData);
        this.paymentTableData.options = {
            additionalEmptyText: this.trans.get('cockpit.pages.financing.additionalEmptyText')
        };

        this.paymentTableData.hasLoaded = true;
    }

    getPaymentEntries(): any[] {
        const dataPayment: any = [];

        this.payments.forEach((payment: any) => {
            const project = this.getInvestmentByPaymentId(payment.hasOwnProperty('investment_id') ? payment.investment_id : payment.signing_id);
            dataPayment.push(this.mapPayments(project, payment));
        });

        return dataPayment;
    }

    mapPayments(project: any, payment: any): any {

        if (!payment.sum) {
            const investmentFactory = new InvestmentFactory();
            const investment = investmentFactory.createInvestment(project, this.userdata);
            const interestRate = investment.getInterest() / 100 + 1;
            payment.sum = `BOND_${String(investment.getInvestSum() * Math.pow(interestRate, (Number(investment.getPeriod()) / 12)))}`;
        }

        return {
            '_expected_at': payment.expected_at,
            '_expire_at': payment.expire_at,
            '_title': project.title,
            '_investment': this.financingService.formatNumber(project.invest_sum),
            '_interest': this.financingService.formatNumber(project.payout_number, 2),
            '_period': (project.datePayBack && project.dateStartDate)
                ? DateFormatHelper.differenceInMonth(project.dateStartDate, project.datePayBack, true)
                : '-',
            '_payoutdate': this.dateFormat(project.datePayBack),
            '_paid_at': payment.paid_at,
            '_payoutsum': payment.sum,
            '_image': project.image,
            '_files': this.financingService.getFiles,
            '_state': project.status || project.state,
            '_investor': project.investor_entity_name
                ? project.investor_entity_name
                : this.userdata.fullname,
            '_createdAt': this.dateFormat(project.created_at),
            '_signingId': project.signing_id,
            '_type': project.type,
            '_id': project.financing_entity,
            '_tradeable': false,
            '_payment_date_overwrite': project.payment_date_overwrite,
            '_payment_value_overwrite': project.payment_value_overwrite,
            '_payment_delay_description': project.payment_delay_description
        };
    }

    mapBondPayments(investment: Investment): any {
        const getFileType = (type: InvestmentType) => {
            switch (type) {
                case InvestmentType.LOAN:
                    return 'loan';
                case InvestmentType.MEZZBOND:
                    return 'mezzbond';
                case InvestmentType.SIGNING:
                    return 'signing';
                default:
                    return 'loan';
            }
        };

        const interestRate = investment.getInterest() / 100 + 1;

        return {
            '_title': investment.getTitle(),
            '_investment': this.financingService.formatNumber(investment.getInvestSum()),
            '_interest': this.financingService.formatNumber(investment.getInterest(), 2),
            '_period': investment.getPeriod(),
            '_payoutdate': investment.getDatePayBack(),
            '_paid_at': investment.getPaidAt(),
            '_payoutsum': String(investment.getInvestSum() * Math.pow(interestRate, (Number(investment.getPeriod()) / 12))),
            '_files': this.financingService.getFiles,
            '_investor': investment.getInvestor(),
            '_createdAt': this.dateFormat(investment.getCreatedAt()),
            '_signingId': investment.getId(),
            '_type': getFileType(investment.getType()),
            '_id': investment.getFinancingEntity(),
            '_tradeable': false
        };
    }
}

export default PageFinancing;
