











    import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
	import KPIStore from '@/components/KPIS/KPIStore';
	import {isString} from 'util';

    @Component
    class KPIS extends Vue {
    	@Prop({default: ''})
		private type!: string;

		@Prop()
		private name!: string;

		@Prop()
		private euro!: boolean;

    	private value: any = null;

        constructor() {
            super();
        }

        mounted() {
			KPIStore.getInstance().getKPIS(this.type, (kpi: any) => {
				this.value = this.convert(kpi.data);
			});
		}

		convert(value) {
        	let valueAsNumber: number = (Number(value) || 0);
        	let isPercentage: boolean = false;

        	if (valueAsNumber < 1) {
				valueAsNumber = valueAsNumber * 100;
				isPercentage = true;
			}

			let number = valueAsNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

			const numbers = number.split('.');

			if (numbers.length > 3) {
				const after = numbers[1].substring(0, 1);
				number = numbers[0] + (after !== '0' ? `,${after}` : '');
			}

			if (Number(number.split('.')[numbers.length - 1]) === 0) {
				number = number.split('.00')[0];
			}

			if (isPercentage) {
				number = number.replace('.', ',');
			}

			if (this.euro) {
				number += ' €';
			}

			return number;
		}
    }

    export default KPIS;
