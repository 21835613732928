










































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ListView from '@/components/ListView/ListView.vue';
import CockpitKeyfigures from '@/components/Cockpit/sections/CockpitKeyfigures.vue';
import CockpitIdentification from '@/components/Cockpit/sections/CockpitIdentification.vue';
import KeyfigureData from '@/components/Cockpit/models/KeyfigureData';
import TableData from '@/components/Cockpit/models/TableData';
import UserData from '@/components/Cockpit/models/UserData';
import DateFormatHelper from '@/components/Helper/DateFormatHelper';
import RestApi from '@/components/Rest/RestApi';

import PagePortfolioLanding from '@/components/Cockpit/pages/PagePortfolioLanding.vue';
import InvestMenu from '@/components/Cockpit/InvestList/InvestMenu.vue';
import TableDataService from '@/components/Cockpit/services/TableDataService';
import PayoutService from '@/components/Cockpit/services/PayoutService';
import ExporoVue from '@/components/ExporoVue';
import CockpitRequestApi from '@/components/Rest/requests/CockpitRequestApi';
import RequestCallback from '@/components/Rest/RequestCallback';
import RestError from '@/components/Rest/models/RestError';
import Axios, {AxiosResponse, AxiosRequestConfig} from 'axios';
import LocalStorage from '@/components/LocalStorage/LocalStorage';
import BaaderBankApiService from '@/components/Baader/services/BaaderBankApiService';
import Row from '@/components/ListView/interfaces/Row';
import Events from '@/events';
import LoadingAnimation from '@/components/LoadingAnimation/LoadingAnimation.vue';
import TableDataConstructionService from '@/components/Cockpit/services/TableDataConstructionService';
import investprogress from '@/components/Cockpit/InvestProgress/InvestProgress.vue';
import SecondaryMarketRequestApi from '@/components/Rest/requests/SecondaryMarketRequestApi';
import Controller from '@/components/Router/Controller';
import Router from '@/components/Router/Router';
import YieldDataEntry from '@/components/Cockpit/interfaces/YieldDataEntry';
import Logger from '@/components/Helper/LogHelper';
import ContentfulRequestApi from '@/components/Rest/requests/ContentfulRequestApi';
    import TanganyApiService from '@/components/Wallet/services/TanganyApiService';

    interface VaultData {
      asset: {
        deleted: boolean;
        name: string;
        token: string;
      };
      balance: {
        balance: number;
        currency: string;
      };
    }
import getReportingUrlFromYieldDataEntry from '@/components/Helper/ReportingUrl';
import * as Sentry from '@sentry/vue';

@Component({
  components: {
    ListView,
    InvestMenu,
    CockpitKeyfigures,
    CockpitIdentification,
    PagePortfolioLanding,
    LoadingAnimation,
    investprogress
  }
})
class PageYield extends Controller implements RequestCallback {

  @Prop() private userdata!: UserData;
  @Prop() private userRestApi!: RestApi;

  private selectable: boolean = true;

  private keyfigures: KeyfigureData[] = [];
  private tableData: TableData;
  private pendingTableData: TableData;
  private payoutService!: PayoutService;
  private cockpitRequestApi: CockpitRequestApi;
  private baaderRequestApi: BaaderBankApiService;
  private secondaryMarketRequestApi: SecondaryMarketRequestApi;
  private tableDataConstructionService: TableDataConstructionService;
  private contentfulRequestApi: ContentfulRequestApi;
  private fallbackImage: string = 'https://via.placeholder.com/300/f4f5f8/000000?text=Kein%20Bild';
  private allContracts: any = [];
  private baaderTableData: any = [];
  private walletTableData: any = [];
  private baaderDataLoaded: boolean = false;
  private walletDataLoaded: boolean = false;
  private rows: null | any[] = null;
  private pendingRows: null | any[] = null;
  private openOffers: any = {};
  private openBids: any = {};
  private isBarLoading: boolean;

  emptyCTA() {
    return {
      text: this.trans.get('invest_button.label'),
      action: (e) => {
        e.preventDefault();
        window.location.href = `https://exporo.${this.cctld.toLowerCase()}/projekte/`;
      }
    };
  }

  constructor() {
    super('PageYield');

    this.selectable = !this.isMobile;
    this.tableData = new TableData([]);
    this.pendingTableData = new TableData([]);
    this.payoutService = new PayoutService();
    this.tableDataConstructionService = new TableDataConstructionService();
    this.contentfulRequestApi = this.restApi.create(new ContentfulRequestApi());

    this.baaderRequestApi = new BaaderBankApiService();

    this.cockpitRequestApi = this.restApi.create(new CockpitRequestApi());

    this.secondaryMarketRequestApi = this.restApi.create(new SecondaryMarketRequestApi());
    const externalUserID = LocalStorage.get(ExporoVue.EXTERNAL_USER_COCKPIT_KEY, undefined);

    if (externalUserID) {
      this.secondaryMarketRequestApi.getPendingDataByUserId(this).addParam('id', externalUserID);
    } else {
      this.secondaryMarketRequestApi.getPendingData(this);
    }

    this.isBarLoading = true;
  }

  mounted() {
    const userid = LocalStorage.get(ExporoVue.USER_ID_KEY, '');
    const externalUserID = LocalStorage.get(ExporoVue.EXTERNAL_USER_COCKPIT_KEY, undefined);
    this.cockpitRequestApi.getInvestors(this).addParam('userId', externalUserID || userid);
    this.cockpitRequestApi.getDocuments(this).addParam('userId', externalUserID || userid);
    this.cockpitRequestApi.getContracts(this);
  }

  onSuccess(response: AxiosResponse<any>, id): void {
    if (id === CockpitRequestApi.GET_DOCUMENTS) {
      this.userdata.addDocuments(response.data);
    } else if (id === CockpitRequestApi.GET_INVERSTORS) {
      this.userdata.addInvestors(response.data.investors);
    } else if (id === CockpitRequestApi.GET_CONTRACTS) {

      this.allContracts = response.data;
      this.userdata.contracts = response.data;

      Events.$emit(`${CockpitRequestApi.GET_CONTRACTS}Loaded`);

      const externalUserID = LocalStorage.get(ExporoVue.EXTERNAL_USER_COCKPIT_KEY, undefined);

      this.baaderRequestApi.getWdp((data: any) => {
        if (data) {
          this.userdata.addBaaderData(data.data);
          this.addBaaderToTableData();
        } else {
          this.baaderDataLoaded = true;
          this.mergeWalletAndBaader();
        }
      }, externalUserID);

                const userid = LocalStorage.get(ExporoVue.USER_ID_KEY, '');
                const tanganyService = new TanganyApiService();
                tanganyService.getWallet(userid).then((result: any) => {
                  if (result && result.data) {
                    const data = result.data;
                    localStorage.setItem('hasWallet', 'true');

                    this.userdata.hasWallet = true;
                    this.addVaultToTableData(data);
                  } else {
                    localStorage.setItem('hasWallet', 'false');
                    this.walletDataLoaded = true;
                    this.userdata.hasWallet = false;
                    this.mergeWalletAndBaader();
                  }
                });
            } else if (id === 'getPendingData') {
                this.openOffers = response.data.offers;
                this.openBids = response.data.bids;

      if (this.allContracts.length > 0) {
        this.createTableDataPending();
      } else {
        Events.$on(`${CockpitRequestApi.GET_CONTRACTS}Loaded`, this.createTableDataPending);
      }
    } else if (id === 'getEntries') {
      this.overwriteTableDataWithContentfulData(response);
    }
  }


      addVaultToTableData(data: VaultData[]): void {
        data.forEach((walletEntry) => {
          if (walletEntry.balance.balance === 0) return;

          this.allContracts.bond.forEach((contract) => {
            if (contract.vault_asset_id === walletEntry.asset.token && contract.product_type !== 'finanzierung' && contract.type !== 'product.mezzbond' ) {
              const entry = this.getEntry(contract, walletEntry, 'vault');
              this.walletTableData.push(entry);
            }
          });
        });
        this.walletDataLoaded = true;
        this.mergeWalletAndBaader();
      }

        overwriteTableDataWithContentfulData(data) {
            data.items.forEach((item) => {
                const currentDevelopments = item.fields.quarterlyYieldReporting[0].fields.currentDevelopment;
                const latestDevelopment = currentDevelopments[currentDevelopments.length - 1];

      const actualPercent = latestDevelopment.payoutActualPercent;
      const expectedPercent = latestDevelopment.payoutYieldPercent;

      const matchingEntry = this.tableData.data.find((el) => {
        return el['_financingEntity'] === item.fields.financingEntityId;
      });


      if (matchingEntry) {
        this.rows?.forEach((row) => {
          if (row.isin === matchingEntry['_isin']) {
            row.expectedPercent = expectedPercent;
            row.actualPercent = actualPercent;
          }
        });
      }
    });
  }

  beforeDestroy() {
    Events.$off(`${CockpitRequestApi.GET_CONTRACTS}Loaded`, this.createTableDataPending);
  }

  onFailure(error: RestError, id?: string): void {
    Logger.tag('PageYield').log(error);
  }

  onClick(row: Row) {
    const tagName = (row.event.target as any).tagName;
    if (tagName !== 'INPUT' && tagName !== 'TH' && tagName !== 'A') {
      this.open(this.getTableEntry(row), row.event);
    }
  }

  getTableEntry(row: Row) {
    let matchingEntry;
    if (this.tableData.data) {
      matchingEntry = this.tableData.data.find((data: any) => {
        return data._isin === row.row.isin;
      });
    }

    if (!matchingEntry) {
      matchingEntry = this.pendingTableData.data.find((data: any) => {
        return data._isin === row.row.isin;
      });
    }
    return matchingEntry;
  }

  open(row: any, event) {
    event.stopPropagation();

    const [type, detailSlug, id] = document.location.href.split('cockpit/');

    Router.navigate('cockpitDetailRoute', [detailSlug, row._fp_id]);
  }

  addBaaderToTableData(): void {
    this.userdata.baaderData.forEach((signing) => {
      let matchingContract: any;
      matchingContract = this.allContracts.bond.find((contract) => {
        if (contract.product_type === 'bestand')
          return contract.isin === signing['XXX-WPNR'];
      });

      if (!matchingContract) {
        matchingContract = this.allContracts.equity.find((contract) => {
          if (contract.type === 'product.equity')
            return contract.isin === signing['XXX-WPNR'];
        });
      }

      if (matchingContract) {
        const entry = this.getEntry(matchingContract, signing, 'baader');
        this.baaderTableData.push(entry);
      }
    });
    this.baaderDataLoaded = true;
    this.mergeWalletAndBaader();
  }

  addWalletToTableData(): void {
    this.userdata.walletData.wallet.balances.forEach((walletEntry) => {
      if (walletEntry.amount === '0') return;

      this.allContracts.bond.forEach((contract) => {
        if (contract.blockchain_asset_id === walletEntry.assetId && contract.product_type !== 'finanzierung' && contract.type !== 'product.mezzbond') {
          const entry = this.getEntry(contract, walletEntry, 'wallet');
          this.walletTableData.push(entry);
        }
      });
    });
    this.walletDataLoaded = true;
    this.mergeWalletAndBaader();
  }

  mergeWalletAndBaader(): void {
    if (this.walletDataLoaded && this.baaderDataLoaded) {
      const dataBestand: any = [];
      this.baaderTableData.forEach((el) => {
        dataBestand.push(el);
      });
      this.walletTableData.forEach((el) => {
        dataBestand.push(el);
      });

      TableDataService.add('yield', dataBestand);


      this.tableData = new TableData(dataBestand);

      const rows: any[] = [];
      let removedOne: any = null;
      this.tableData.data.forEach((data: any) => {
        if (data._title !== 'MP-Test Schulbehörde in der Landeshauptstadt') {
          rows.push({
            _image: data._image,
            _fp_id: data._fp_id,
            isin: data._isin,
            title: data._title,
            investment: data._investment,
            expectedInterest: data._expectedTotalInterest / 100,
            createdAt: data._createdAt,
            action: true,
            expireAt: data._expireAt,
            reporting: getReportingUrlFromYieldDataEntry(data, this.userdata.contracts),
          });
        } else {
          removedOne = data;
        }
      });

      this.rows = rows;

      let length = dataBestand.length.toString();
      let sum = this.calcTotalInvestment(dataBestand);

      if (removedOne) {
        length = (dataBestand.length - 1).toString();
        sum -= removedOne._investment;
      }

      this.keyfigures.push(new KeyfigureData(
          this.trans.get('cockpit.pages.portfolio.keyfigures.equity_investments_length'),
          length
      ));

      this.keyfigures.push(new KeyfigureData(
          this.trans.get('cockpit.pages.portfolio.keyfigures.invested_total_equity_sum'),
          sum.toLocaleString('de-DE') + '€'
      ));

      this.tableData.hasLoaded = true;
      this.getContentfulData();
    }
  }

  getContentfulData() {
    this.contentfulRequestApi.getEntries({
      query: {
        'content_type': 'project',
        'fields.quarterlyYieldReporting[exists]': 'true'
      },
      limit: 1000
    }, this);
  }

  getEntry(contract: any, signing: any, type: string): YieldDataEntry {
    const project: any = this.userdata.projects[contract.financing_entity] ? this.userdata.projects[contract.financing_entity] : {};
    let investmentSum: number = this.calcInvestmentSum(contract, signing, type);

    if ('baader' === type && 0 === investmentSum) {
      investmentSum = Infinity;
    }

    const assetId = contract.vault_asset_id ? contract.vault_asset_id : (signing.assetId ? signing.assetId : null);

    return {
      _id: contract.financing_entity,
      _fp_id: contract.fp_id ??  '',
      _financingEntity: contract.financing_entity ?? '',
      _contractId: contract.id,
      _title: contract.title ?? signing.title,
      _isin: contract.isin,
      _wkn: contract.wkn,
      _type: contract.bond_type ? 'signing' : 'equity',
      _piece_value: contract.piece_value,
      _period: contract.runningtime_in_months,
      _nextPayout: this.getNextPayout(contract),
      _tradeable: this.checkTradeAble(contract),
      _expectedInterest: contract.return_on_equity_rate_miete,
      _expectedTotalInterest: contract.return_on_equity_rate_overall,
      _status: this.getFundedStatus(contract),
      _createdAt: type === 'pending'
          ? DateFormatHelper.getFormattedDateFromString(signing.created_at, true)
          : DateFormatHelper.getFormattedDateFromString(this.getCreatedAt(contract, signing), true),
      _pendingType: this.trans.get('cockpit.pages.portfolio.data_pending_type.bond_purchase'),
      _repositoryType: signing.contract && signing.contract.blockchain_asset_id ? 'wallet' : type,
      _currentState: type === 'pending' ? signing.state : '-',
      _isPaid: type === 'pending' ? signing.is_paid : '-',
      _image: {path: project ? project.image : this.fallbackImage},
      _investment: investmentSum,
      _pieces: investmentSum / contract.piece_value,
      _assetId: assetId,
      _expireAt: contract.expire_at
    };
  }

  getCreatedAt(contract: any, signing: any) {
    if (signing['XXX-WPNR']) {
      const matchingInvestment = this.userdata.rawData.investmentData.bestand.investments.find((investment) => {
        return investment.isin === signing['XXX-WPNR'];
      });

      if (!matchingInvestment) {
        // const secondaryMarketSigning = this.userdata.rawData.investmentData.handelsplatz.data.find((investment) => {
        //     return investment.isin === signing['XXX-WPNR'];
        // });

        return '1970-01-01';
      }

      return matchingInvestment ? matchingInvestment.created_at : '1970-01-01';
    } else if (signing.assetId) {
      const matchingInvestment = this.userdata.rawData.investmentData.bestand.investments.find((investment) => {
        return investment.contract.blockchain_asset_id === signing.assetId;
      });

      return matchingInvestment ? matchingInvestment.created_at : '1970-01-01';
    } else {
      return signing.created_at;
    }
  }

  createTableDataPending(): void {
    const dataPending: any = [];

    this.userdata.rawData.investmentData.bestand.investments.forEach((signing) => {
      if (signing.hasOwnProperty('state') && ('successful' !== signing.state && 'order_successful' !== signing.state))
        dataPending.push(this.getEntry(signing.contract, signing, 'pending'));
    });

    this.openBids.forEach((bid) => {
      let matchingContract = this.allContracts.equity.find((contract) => {
        return contract.isin === bid.isin;
      });

      if (!matchingContract) {
        matchingContract = this.allContracts.bond.find((contract) => {
          return contract.isin === bid.isin;
        });
      }

      const project: any = this.userdata.projects[matchingContract.financing_entity] ? this.userdata.projects[matchingContract.financing_entity] : {};
      const type = this.trans.get('cockpit.pages.portfolio.data_pending_type.bond_purchase_secondary_market');
      const image = {path: project ? project.image : this.fallbackImage};
      const status = this.getFundedStatus(matchingContract);
      const nextPayout = this.getNextPayout(matchingContract);

      dataPending.push(this.tableDataConstructionService.getSecondaryMarketEntry(bid, type, matchingContract, image, status, nextPayout));
    });

    TableDataService.add('pendingData', dataPending);
    this.pendingTableData = new TableData(dataPending);

    const pendingRows: any[] = [];
    this.pendingTableData.data.forEach((data: any) => {
      pendingRows.push({
        _fp_id: data.fp_id,
        mode: 'span',
        label: data._isin,
        html: false,
        children: [{
          _image: data._image,
          isin: data._isin,
          title: data._title,
          pieces: data._pieces,
          investment: data._investment,
          createdAt: data._createdAt,
          type: data._pendingType,
          action: true,
          _investment: data,
        }]
      });
    });

    this.pendingRows = pendingRows;

    this.pendingTableData.hasLoaded = true;
  }

  getFundedStatus(contract: any) {
    const matchingProject = Object.keys(this.userdata.projects).find((financingEntityId) => {
      return this.userdata.projects[financingEntityId].id === contract.financing_entity;
    });

    return matchingProject ? this.userdata.projects[matchingProject].status : 'no status found';
  }

  calcTotalInvestment(data: any[]): number {
    let totalInvestment = 0;
    if (data.length && data.length > 0) {
      data.forEach((el) => {
        if (el._investment !== Infinity) {
          totalInvestment = totalInvestment + el._investment;
        }
      });
    }
    return totalInvestment;
  }

        calcInvestmentSum(contract: any, signing: any, type: string) {
            if (type === 'baader') return parseInt(signing['XXX-NW-M'].split('.')[0], 0);
            if (type === 'wallet') return signing.amount * contract.piece_value;
            if (type === 'pending') return signing.invest_sum;
            if (type === 'secondaryMarket') return contract.piece_value * contract.bid_pieces;
            if (type === 'vault') return signing.balance.balance * contract.piece_value;
        }

  readableFilename(url: string): string {
    const indexFile = url.lastIndexOf('/');
    const indexExtension = url.lastIndexOf('.');
    return url.substring(indexFile + 1, indexExtension);
  }

  getNextPayout(investment: any): string {
    let nextPayoutString = this.payoutService.getNextPayoutForBestand(investment.first_payback_at);
    if (nextPayoutString) {
      nextPayoutString = nextPayoutString.split('T')[0].split(' ')[0].split('-')[0] + '-' + nextPayoutString.split('T')[0].split(' ')[0].split('-')[1] + '-15';
    }
    return nextPayoutString ? DateFormatHelper.getFormattedDateFromString(nextPayoutString, true) : 'Bereits zurückgezahlt';
  }

  checkTradeAble(contract: any): boolean {
    return 'product.equity' === contract.type
        || (contract.bond_type === 'bond-full' && contract.product_type !== 'finanzierung')
        || ('bond-blockchain' === contract.bond_type);
  }

}

export default PageYield;
