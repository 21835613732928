























import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Events from '@/events';
import CockpitRouter from '@/components/Cockpit/CockpitRouter';
import Router from '@/components/Router/Router';
import ExporoVue from '@/components/ExporoVue';

@Component
class CockpitOptions extends ExporoVue {

    @Prop({default: false}) private open!: boolean;
    @Prop() private name!: string;
    @Prop() private options!: any[][];
    @Prop({default: ''}) private selection!: string;
    @Prop() private noNavigation!: boolean;

    mounted() {
        Events.$on('updateBubble', (data) => {
            this.$nextTick(() => {
                this.options.forEach((optionGroup) => {

                    optionGroup.forEach((option) => {
                        if (data.page === option.key) {
                            this.navigationChangedHandler(option);
                        }
                    });
                });
            });
        });
    }

    clicked(option) {
        if (option.active) {
            this.$emit('selected', option);

            if (
                this.trans.get('cockpit.menu.questions') === option.value ||
                this.trans.get('cockpit.menu.logout') === option.value ||
                this.trans.get('cockpit.menu.account') === option.value ||
                this.trans.get('cockpit.menu.password_change') === option.value ||
                this.trans.get('cockpit.menu.edit_user') === option.value ||
                this.trans.get('cockpit.menu.trade_cockpit') === option.value
            ) {
                option.handler();
            } else {
                this.noNavigation
                    ? this.navigationChangedHandler(option)
                    : Router.navigate('cockpitRouteMenu', [option.key]);
            }
        }
    }

    navigationChangedHandler(option) {
        option.handler();
    }


}

export default CockpitOptions;

