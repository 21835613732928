
































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import UserData from '@/components/Cockpit/models/UserData';
import DateFormatHelper from '@/components/Helper/DateFormatHelper';
import ExporoVue from '@/components/ExporoVue';
import PayoutService from '@/components/Cockpit/services/PayoutService';
import PageData from '@/components/Cockpit/models/PageData';
import CockpitExpander from '@/components/Cockpit/Controls/CockpitExpander.vue';


@Component({
    components: {
        CockpitExpander,
    }
})
class HistoryPanel extends ExporoVue {

    @Prop() private userdata!: UserData;
    @Prop() private pagedata!: PageData;
    private historyData: any[] = [];
    private payoutService!: PayoutService;
    private active: number = -1;
    private activeSigningId: string = '';
    private documents: any = {};

    constructor() {
        super();
        this.payoutService = new PayoutService();
        this.userdata.rawData.investmentData.bestand.investments.forEach((el) => {
            if (el.isin === this.pagedata.data._isin) {
                this.historyData.push(el);
            }
        });
    }

    setActive(active) {
        if (this.active !== active) {
            this.updateDocumentData(active);
            this.active = active;
        } else {
            this.active = 99;
        }
    }

    updateDocumentData(active?: number): void {
        this.activeSigningId = active ? Object.keys(this.pagedata.documentData)[active] : Object.keys(this.pagedata.documentData)[0];
        this.documents.data = this.pagedata.documentData[this.activeSigningId];
        this.$forceUpdate();
    }

    getDate(date) {
        return DateFormatHelper.getFormattedDateFromString(date, true);
    }

    getSum(sum) {
        return `${sum} €`;
    }

    getNextPayout(investment) {
        let nextPayout;
        if (investment.contract) {
            const nextPayoutString = this.payoutService.getNextPayoutForBestand(investment.first_payback_at);
            nextPayout = nextPayoutString ? DateFormatHelper.getFormattedDateFromString(nextPayoutString, true) : this.trans.get('cockpit.kpi.historyPanel.received_payback');
        } else {
            nextPayout = '-';
        }

        return nextPayout;
    }
}

export default HistoryPanel;
